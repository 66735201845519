import { compare, isUndefined } from '@iheartradio/web.utilities';
import { useRef, useMemo, useEffect, useState } from 'react';
import { invariant } from '@epic-web/invariant';
import { Theme } from '@iheartradio/web.companion';
import { useRouteLoaderData, useFetcher } from '@remix-run/react';
import { T as ThemeFormSchema } from '../../dist/schemas-2c75caf2.esm.js';
export { T as ThemeFormSchema } from '../../dist/schemas-2c75caf2.esm.js';
import { parseWithZod } from '@conform-to/zod';
import * as Analytics from '@iheartradio/web.analytics';
import 'zod';

/**
 * @returns the request info from the root loader
 */
function useRequestInfo(rootError = false) {
  const data = useRouteLoaderData('root');
  if (rootError) {
    // root error pages are always light theme
    return {
      hints: {
        theme: Theme.Light
      },
      userPrefs: {
        theme: Theme.Light
      }
    };
  }
  invariant(data?.requestInfo, 'No requestInfo found in root loader');
  return data.requestInfo;
}

/**
 * @returns an object with the client hints and their values
 */
function useHints(rootError = false) {
  const requestInfo = useRequestInfo(rootError);
  return requestInfo.hints;
}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
function useTheme(rootError = false) {
  const hints = useHints(rootError);
  const requestInfo = useRequestInfo(rootError);
  const optimisticMode = useOptimisticThemeMode();
  if (optimisticMode) {
    return optimisticMode === 'system' ? hints.theme : optimisticMode;
  }
  return requestInfo.userPrefs.theme ?? hints.theme;
}
const SET_THEME_FETCHER_KEY = 'SET_THEME';

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
function useOptimisticThemeMode() {
  const themeFetcher = useFetcher({
    key: SET_THEME_FETCHER_KEY
  });
  if (themeFetcher && themeFetcher.formData) {
    const submission = parseWithZod(themeFetcher.formData, {
      schema: ThemeFormSchema
    });
    if (submission.status === 'success') {
      return submission.value.theme;
    }
  }
}

function isPrimitive(value) {
  return /^[bns]/.test(typeof value);
}
function checkDependencies(dependencies, fnName) {
  if (!dependencies || dependencies.length === 0) {
    throw new Error(`${fnName} should not be used with no dependencies. Use React.useEffect instead.`);
  }
  if (dependencies.every(isPrimitive)) {
    throw new Error(`${fnName} should not be used with dependencies that are all primitive values. Use React.useEffect instead.`);
  }
}
function useDeepCompareMemoize(value) {
  const ref = useRef(value);
  const signalRef = useRef(0);
  if (!compare(value, ref.current)) {
    ref.current = value;
    signalRef.current += 1;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ref.current, [signalRef.current]);
}
function useDeepCompareEffect(callback, dependencies) {
  if (process.env.NODE_ENV !== 'production') {
    checkDependencies(dependencies, 'useDeepCompareEffect');
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(callback, useDeepCompareMemoize(dependencies));
}

function useIsOnline() {
  const [isOnline, setIsOnline] = useState(isUndefined(globalThis?.window) ? true : window.navigator.onLine);
  useEffect(() => {
    function setOnline() {
      setIsOnline(window.navigator.onLine);
    }
    window.addEventListener('load', setOnline);
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOnline);
    return function cleanup() {
      window.removeEventListener('load', setOnline);
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOnline);
    };
  }, []);
  return isOnline;
}

function useTrackVisibilityChange(analytics) {
  useEffect(() => {
    function trackVisibility() {
      if (document.visibilityState === 'hidden') {
        analytics.track({
          type: Analytics.eventType.enum.Background
        });
      } else {
        analytics.track({
          type: Analytics.eventType.enum.Foreground
        });
      }
    }
    window.document.addEventListener('visibilitychange', trackVisibility);
    return () => {
      window.document.removeEventListener('visibilitychange', trackVisibility);
    };
  }, []);
}

export { SET_THEME_FETCHER_KEY, useDeepCompareEffect, useDeepCompareMemoize, useIsOnline, useOptimisticThemeMode, useRequestInfo, useTheme, useTrackVisibilityChange };
